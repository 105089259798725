.footer {
  border-radius: 0;
  text-align: center;
  margin-top: 100px; /*theme.spacing(2);*/
}

.report {
  padding: 16px 0;
}

.footerMain {
  padding: 16px 0;
}
