.highlight {
    background: #1717176b;
}

.fa-twitter {
    font-size: 3rem;
}

.fa-heart {
    color: red;
}

.dont-break-out {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

html {
    scroll-behavior: smooth;
    min-width: 372px;
}

input:-webkit-autofill,
.my-class:-webkit-autofill {
    -webkit-transition-delay: 9999999s;
    transition-delay: 9999999s;
}
